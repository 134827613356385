import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { Layout, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'
import { heading, body } from '../styles/type'
import { parseACF, Fade } from '../utils'
import { headerHeight } from '../components/Header/Header'

const JournalSingle = props => {
    const { previewData, pageContext } = props
    const data = previewData || pageContext.data
    console.log(data)

    const featureImageBlock = {
        acf_fc_layout: 'full_width_image',
        image: data?.thumbnail_image,
        use_side_margins: true,
    }

    const renderBlocks = blocks => {
        if (!blocks) return

        return blocks.map((block, i) => {
            return (
                <Block
                    key={i}
                    index={i+1}
                    layout={block.acf_fc_layout}
                    isArticle={true}
                    {...block}
                />
            )
        })
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <FeatureImage
                    as={Block}
                    index={0}
                    layout={'single_image'}
                    isArticle={true}
                    forceLandscape={true}
                    {...featureImageBlock}
                />
                <Introduction>
                    <Container>
                        <Info>
                            <Heading>
                                {`Entry No.${pageContext.index + 1}`}
                            </Heading>
                            <Description>
                                {`${data.publishedDate}`}
                            </Description>
                        </Info>
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: data.title,
                            }}
                        />
                        {data.byline && (
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.byline,
                                }}
                            />
                        )}
                    </Container>
                </Introduction>
                {data && data.blocks && renderBlocks(data.blocks)}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Wrapper = styled.div``

const Container = styled.div`
    ${container}
    ${padding}
    max-width: 820px;
`

// Feature Image

const FeatureImage = styled.div``

// Introduction

const Introduction = styled.div`
    ${tw`text-center`};

    ${media.mob`
        ${tw`pt-8`};
    `}

    ${Info} {
        &, * {
            ${tw`uppercase`};
            ${heading};
            font-size: 16px;
        }

        ${Description} {
            ${tw`mt-2`};
        }
    }

    ${Container} > ${Heading},
    ${Container} > ${Description} {
        ${tw`mt-32`};

        ${media.mob`
            ${tw`mt-16`};
        `}
    }

    ${Container} > ${Heading} {
        ${heading};
        font-size: 42px;

        ${media.mob`
            font-size: 32px;
        `}
    }

    ${Container} > ${Description} {
        ${body};
        font-size: 24px;

        ${media.mob`
            font-size: 18px;
        `}
    }
`

export default JournalSingle
